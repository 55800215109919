import { useContext, useEffect, useRef, useState } from "react"
import { FiltersContext } from "../context/FiltersContext.jsx"
import { getAll } from "../../../../services/abm.js"
// import productos from "../hooks/productMock.json"

export function useFilters() {
    const { filters, setFilters } = useContext(FiltersContext)
    const [product, setProduct] = useState([])
    // console.log({filters.filters});
    function useProducts(product) {
        if (!product || product.length === 0) {
            return [];
        }
        
        return product.map(item => ({
            id: item.id,
            division: item.division,
            porpuse: item.purpose,
            price: item.purpose?.publicPrice,
            title: item.purpose?.primaryTitle,
            subtitle: item.purpose?.secondaryTitle,
            image: item.photos,
            description: item.purpose?.description,
            categories: item.categorie,
            categorie: item.categorie?.name,
            parentCategory: item.categorie?.parentCategory?.parentCategory,
            parentCategoryName: item.categorie?.parentCategory?.name,
            variants: item.variants
        }));
    }

    const mapedProducts = useProducts(product)
    const componentMounted = useRef(true)

    useEffect(() => {
        // setProduct(productos.products)
        if (filters.priceOrder === 0) {
            // products/filters
            getAll("products")
                .then((res) => {
                    if (componentMounted.current) {
                        // console.log(res.products);
                        setProduct(res.products);
                    }
                })
                .catch((err) => console.log(err));

            return () => { // This code runs when component is unmounted
                componentMounted.current = false; // (4) set it to false when we leave the page
            }
        }
    }, [filters])


    function maxPrice(list) {

        list.sort((a, b) => {
            if (filters.priceOrder === 1)
                return a.price - b.price
            else if (filters.priceOrder === 2)
                return b.price - a.price
        })
    }
    maxPrice(mapedProducts)

    const mapedCategories = (product) => {
        return product.categories.map((categoria) => {
            return categoria.name;
        });
    };
    // console.log({ filters: filters.filters });
    
    function filterProducts(product) {
        return mapedProducts.filter(product => {
            return (
                (
                    filters.priceOrder === 0 ||
                    filters.priceOrder === 1 ||
                    filters.priceOrder === 2
                )
                &&
                (
                    filters.category === "Todos los productos" ||
                    mapedCategories(product).includes(filters.category)
                )
            );
        });
    }
    
    return { filterProducts, filters, setFilters, mapedProducts, maxPrice };

}