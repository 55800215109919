import { useEffect, useRef, useState } from "react"
import { useFilters } from "./hooks/useFilters"
import { getAll } from "../../../services/abm"

export default function Filters({categoria}) {

    const { filters, setFilters } = useFilters()
    const [categorie, setCategorie] = useState()
    const componentMounted = useRef(true)

    const handleFilter = (filter)=> {
        setFilters(filter)
    }
    const handleChangeRandomPrice = () => {
        handleFilter({ ...filters, priceOrder: 0 })
    }
    const handleChangeMinPrice = () => {
        handleFilter({ ...filters, priceOrder: 1 })
    }
    const handleChangeMaxPrice = () => {
        handleFilter({ ...filters, priceOrder: 2 })
    }

    const handleChangeCategory = (event) => {
        handleFilter(prevState => ({
            ...prevState,
            category: event.target.value
        }))
    } 
    useEffect(() => {
        console.log("Valor de 'categoria':", categoria);
      
        if (categoria) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            category: categoria,
          }));
          console.log("Filtro aplicado.");
        }
      }, [categoria]);

    useEffect(() => {
        getAll("categories/terciarias")
            .then((res) => {
                if (componentMounted.current) {
                    const resCategorie = res
                    setCategorie(resCategorie.categories)
                    // console.log(resCategorie);
                }
            })
            .catch((err) => console.log(err));

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // (4) set it to false when we leave the page
        }
    }, [categoria])

    return (
        <aside className="filtros-container">
            <div className="filtro-precio">
                <h3 className="filtro-titulo">ORDENAR POR</h3>
                <ul>
                    <li > <input type="text" onClick={handleChangeRandomPrice} value="Aleatorio" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeMinPrice} value="Precio - Menor a mayors" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeMaxPrice} value="Precio - Mayor a menor" readOnly /></li>
                </ul>
            </div>
            <div className="categorias">
                <h3 className="filtro-titulo">CATEGORÍAS</h3>
                <ul>
                <li> <input type="text" onClick={handleChangeCategory} value={"Todos los productos"} readOnly/></li>
                    {categorie?.map((cate, index) => {
                                                return (
                                                    <li key={index}> <input type="text" onClick={handleChangeCategory} value={cate.name} readOnly/></li>
                                                )
                                            })}
                </ul>
            </div>
        </aside>
    )
}
